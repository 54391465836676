var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[(!_vm.isMobile())?_c('app-bar'):_vm._e(),_c('v-main',{},[_c('header-back-title',{attrs:{"title":"Funcionários","btnPath":"/admin/users/_newUser","btnName":"Novo"}}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-sheet',{attrs:{"min-height":"70vh","rounded":"lg"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.users,"item-key":"code","items-per-page":50,"search":_vm.search,"custom-filter":_vm.filterUsers,"hide-actions":"","hide-default-footer":"","loading-text":"Carregando... Por favor aguarde"},on:{"click:row":_vm.clickRow},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"Pesquise o Funcionário"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.disabled",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":!item.disabled ? 'green' : 'red'}},[(!item.disabled)?_c('v-icon',[_vm._v(" mdi-check ")]):_c('v-icon',[_vm._v(" mdi-close ")])],1)]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(new Date(item.createdAt) .toLocaleDateString('pt-BR', { year: 'numeric', month: ('numeric'), day: 'numeric', hour: 'numeric', minute: 'numeric' }))+" ")]}},{key:"item.updatedAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(new Date(item.updatedAt) .toLocaleDateString('pt-BR', { year: 'numeric', month: ('numeric'), day: 'numeric', hour: 'numeric', minute: 'numeric' }))+" ")]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.isAdmin(item.type) ? 'primary' : ''}},[_vm._v(" "+_vm._s(_vm.getTypePtBR(item.type))+" ")])]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }